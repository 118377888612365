import React from "react";

export default function Suggestion() {
  return (
    <div>
      <h1>***我講我我做, 你判斷***</h1>
      <p>講睇到邊? 行動? (點解?)</p>

      <h1>***留意我有冇過線***</h1>
    </div>
  );
}
