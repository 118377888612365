import React from "react";

export default function Tips() {
  return (
    <div>
      <h1 style={{ display:"flex", justifyContent:"center",alignItems:"center",height:"15vh"}}>Tips 等小巴行先</h1>
      <img src="/img/T1.jpeg" style={{ width: 500,borderRadius:30 ,height:300, marginBottom:15 }} alt="test" />
      <br />
      <img src="/img/T2.jpeg" style={{ width: 500,borderRadius:30 ,height:300, marginBottom:15 }} alt="test" />
      <br />
      <img src="/img/T3.jpeg" style={{ width: 500,borderRadius:30 ,height:300, marginBottom:15 }} alt="test" />
      <br />
      <img src="/img/Tips1.jpeg" style={{ width: 500,borderRadius:30 ,height:300, marginBottom:15 }} alt="test" />
      <br />
    </div>
  );
}
